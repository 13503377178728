<template>
  <div>
    <v-select
      v-model="cityId"
      :items="cities"
      label="選擇縣市"
      hide-details
      class="mb-4"
      solo
      item-text="name"
      item-value="id"
      @change="getSchoolsWithSSID"
    ></v-select>
    <v-card class="pa-4">
      <v-row class="grey--text">
        <v-col cols="4" md="2">學校名稱</v-col>
        <v-col cols="4" md="3">SSID</v-col>
      </v-row>
      <div v-for="school in schools" :key="school.schoolId">
        <SSIDEditor :school="school" :cityId="cityId" @update="update" />
      </div>
    </v-card>
  </div>
</template>

<script>
import City from '@/api/admin/City'
import SSIDEditor from './SSIDEditor.vue'
import SSID from '@/api/admin/SSID'

export default {
  name: 'SSID',
  mixins: [],
  components: { SSIDEditor },
  props: {},
  data: function () {
    return {
      cityId: null,
      cities: [],
      schools: [],
      headers: [
        {
          text: '學校名稱',
          value: 'name'
        },
        {
          text: 'SSID',
          value: 'SSID'
        },
        {
          text: '',
          value: 'action',
          align: 'right'
        }
      ]
    }
  },
  watch: {
    cityId() {
      this.getSchoolsWithSSID()
    }
  },
  mounted: function () {
    this.getCity()
    this.getSchoolsWithSSID()
  },
  destroyed() {},
  methods: {
    getCity() {
      City.list()
        .then((resp) => {
          this.cities = resp.data
          if (this.cities.length === 1) this.cityId = this.cities[0].id
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    getSchoolsWithSSID() {
      if (!this.cityId) {
        return
      }
      SSID.list(this.cityId)
        .then((resp) => {
          this.schools = resp.data.sort((a, b) => a.ssId - b.ssId)
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    update(newSchools) {
      this.schools = newSchools.sort((a, b) => a.ssId - b.ssId)
    }
  }
}
</script>

<style></style>
