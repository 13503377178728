<template>
  <v-row>
    <v-col cols="4" md="2" class="align-self-center">{{ school.name }}</v-col>
    <v-col cols="4" md="3">
      <v-text-field
        outlined
        dense
        background-color="white"
        v-model="SSID"
        :disabled="!editMode || isLoading"
        flat
        hide-details
      >
      </v-text-field>
    </v-col>
    <v-col>
      <v-btn
        v-if="!editMode"
        color="primary"
        :loading="isLoading"
        @click="editMode = true"
      >
        編輯
      </v-btn>
      <template v-else>
        <v-btn color="accent" @click="confirm">確認</v-btn>
        <v-btn
          v-if="editMode"
          color="accent"
          outlined
          class="ml-2"
          @click="cancel"
        >
          取消
        </v-btn>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import SSID from '@/api/admin/SSID'

export default {
  props: {
    school: {
      type: Object,
      default: () => {}
    },
    cityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editMode: false,
      SSID: null,
      isLoading: false
    }
  },
  mounted() {
    this.SSID = this.school?.ssId
  },
  watch: {
    'school.ssId': function (newVal) {
      this.SSID = newVal
    }
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    async confirm() {
      this.editMode = false
      this.isLoading = true
      try {
        const response = await SSID.update(this.cityId, [
          { schoolId: this.school.schoolId, ssId: this.SSID }
        ])
        this.showMsg({
          message: '設定成功',
          closable: true,
          time: 3000,
          color: 'success'
        })
        this.$emit('update', response.data)
      } catch (e) {
        console.error(e)
        this.SSID = this.school.ssId
        this.showMsg({
          message: '設定失敗',
          closable: true,
          time: 3000,
          color: 'error'
        })
      }
      this.isLoading = false
    },
    cancel() {
      this.editMode = false
      this.SSID = this.school.ssId
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';

::v-deep.v-input--is-disabled {
  fieldset {
    border-color: transparent;
  }
  .v-input__slot {
    background: var(--v-ems-light-grey-base) !important;
  }
  input {
    color: var(--v-ems-bluegrey-base) !important;
  }
}
</style>
